<template>
  <div id="app">
    <!-- 背景图片，标题放置在图片上 -->
    <div class="background-container">
      <img src="../data/ionosphere.jpg" alt="背景图片" class="background-img" />
      <div class="title-overlay">
        <h1 class="main-title">电离层三维层析结果</h1>
        <h2 class="subtitle">Ionospheric 3D Tomographic</h2>
      </div>
    </div>

    <div v-if="data">
      <!-- 导航按钮 -->
      <div class="button-container">
        <button v-for="(imgUrl, index) in data.imageUrls" :key="index" @click="scrollToImage(index)">
          {{ data.imageTitles[index] }}
        </button>
      </div>

      <!-- 显示四幅图片，使用 v-for 循环 -->
      <div class="image-grid">
        <div v-for="(imgUrl, index) in data.imageUrls" :key="index" class="image-container" :id="'image-' + index">
          <img :src="imgUrl" alt="展示的图片" class="responsive-img" />
          <p class="image-title">图 {{ index + 1 }} {{ data.imageTitles[index] }}</p> <!-- 使用单独的标题 -->
        </div>
      </div>
    </div>

    <div v-else>
      <p>正在加载数据...</p>
    </div>
    
    <footer class="footer">
      <p class="copyright">
        © 2024 Ting Zhang. All rights reserved.
      </p>
      <a href="https://beian.miit.gov.cn/" target="_blank" class="beian-link">
        鲁ICP备2024121410号-1
      </a>
      &nbsp;&nbsp;&nbsp;&nbsp;  <!-- 添加空格 -->
      <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" class="beian-link">
        <img src="../data/beian.png" alt="备案图标" class="icon" />
        鲁公网安备37011302000489号
      </a>      
      <p class="note">
        建议您使用Chrome、Firefox、Edge、IE10及以上版本和360等主流浏览器浏览本网站
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },
  methods: {
    fetchData() {
      // 修改为云服务器上 data.json 文件的路径
      fetch('../data/data.json') 
        .then(response => response.json())
        .then(data => {
          data.imageUrls = data.imageUrls.map(url => `../data${url}`);
          this.data = data;
        })
        .catch(error => console.error('Error fetching data:', error));
    },
    scrollToImage(index) {
      const element = document.getElementById(`image-${index}`);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  },
  mounted() {
    this.fetchData();
    // 每60秒重新获取一次数据
    setInterval(this.fetchData, 60000);
  }
};
</script>


<style scoped>
/* 背景图片样式，拉长到页面两端，高度缩小到一半 */
.background-container {
  position: relative;
  width: 100%;
  height: 15vw; 
  overflow: hidden;
}

.background-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保图片充满背景 */
}

/* 标题放置在背景图片上，字体大且颜色为白色 */
.title-overlay {
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.main-title {
  font-family: Arial, sans-serif;
  font-size: 2.5vw; /* 增大字号 */
  color: white; /* 白色字体 */
  margin: 0;
}

.subtitle {
  font-family: Arial, sans-serif;
  font-size: 1.65vw; /* 增大字号 */
  color: white; /* 白色字体 */
  margin: 0;
}

/* 导航按钮 */
.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.5vw;
  padding: 0 18.2vw;
  margin-bottom: 20px;
}

.button-container button {
  padding: 10px 30px;
  font-size: 1vw;
  cursor: pointer;
  background-color: #6A5ACD;
  color: white;
  border: none;
  border-radius: 5px;
}

.button-container button:hover {
  background-color: #45a049;
}

/* 图片网格 */
.image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1vw;
  padding: 0 18.2vw; /* 两侧留出空白 */
}

/* 图片容器样式 */
.image-container {
  border: 2px solid #ddd;
  padding: 1vw;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.responsive-img {
  max-width: 100%;
  height: auto;
  border-radius: 100px;
}

.image-title {
  font-size: 1vw;
  margin-top: 10px;
  color: #333;
}

/* 页面底部格式 */
.footer {
  text-align: center;
  padding: 5px 0;
  background-color: #f8f8f8;
  margin-top: 30px;
}

.copyright {
  font-size: 16px;
  color: #777;
  margin-bottom: 5px;
}

.beian-link {
  color: #555;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.beian-link:hover {
  text-decoration: underline;
}

.note {
  font-size: 16px;
  color: #777;
  margin-top: 5px;
}

/* 针对小屏幕设备的适配 */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: 1fr; /* 切换为一列布局 */
  }
  
  .main-title {
    font-size: 4vw; /* 更大字号，适合小屏 */
  }
  
  .subtitle {
    font-size: 2.5vw;
  }

  .button-container {
    padding: 0 2vw; /* 减少按钮左右间距 */
  }

  .button-container button {
    padding: 2px 4px; /* 按钮小一些 */
    font-size: 2vw;
  }

  .image-title {
    font-size: 2vw; /* 调整标题大小 */
  }
  /* 页面底部格式 */

  .copyright {
    font-size: 2vw;
  }

  .beian-link {
    font-size: 2vw;
  }

  .icon {
    width: 2vw;
    height: 2vw;
  }

  .note {
    font-size: 2vw;
  }
}
</style>

